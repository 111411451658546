<template>
  <div class="container_con">
    <el-card>
      <div class="search_box">
        <div style="margin-top:0px">
            <span class="mr_20">
              产品编号或名称：
              <el-input
                v-model="queryParams.title"
                placeholder="请输入产品编号或名称"
                style="width:245px"
              />
            </span>
            <span class="mr_20">
              货架：
              <el-select v-model="queryParams.warehouse_id" placeholder="请选择货位" clearable 
                  style="width:300px" filterable>
                  <el-option :label="item.cargo_space_name" v-for="(item,index) in hwlist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </span>
            <span class="mr_20">
              货位数量：
              <el-select v-model="queryParams.warehouse_num" clearable style="width:300px">
                  <el-option :label="item.title" v-for="(item,index) in sllist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </span>
            <span class="mr_20">
              批号数量：
              <el-select v-model="queryParams.number_num" clearable style="width:300px">
                  <el-option :label="item.title" v-for="(item,index) in sllist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </span>
          <el-button type="primary" @click="handleQuery()">搜索</el-button>
          <el-button @click="resetQuery()">重置</el-button>
        </div>
      </div>
      <div style="margin-top:20px">
        <el-table :data="list" v-loading="loading" style="width: 100%">
          <el-table-column prop="stock" label="货位数量" />
          <el-table-column prop="batch_number" label="批号" />
          <el-table-column prop="product_name" label="产品名称" />
          <el-table-column prop="product_no" label="产品编号" />
          <el-table-column prop="spec_value_name" label="规格" />
          <el-table-column prop="origin" label="产地" width="150" />
          <el-table-column prop="manufacturer" label="生产厂家" width="180" />
          <!-- <el-table-column prop="name" label="计量规格" /> -->
          <el-table-column prop="warehouse_name" label="货架号" />
          <el-table-column fixed="right" label="操作" width="100">
            <template v-slot="scope">
              <el-button
                type="text"
                size="small"
                @click="$router.push('/batList?id='+scope.row.batch_number)"
                >批号列表</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
  <pagination
    v-show="total>0"
    :total="total"
    v-model:page="queryParams.page"
    v-model:limit="queryParams.size"
    @pagination="getList"
  />
</template>

<script>
export default {
  name: "warrant",
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
          page: 1,
          size: 10,
          
      },
      total:0,
      list:[],
      hwlist:[],
      sllist:[]
    };
  },
  created() {
      this.queryParams=window[this.$route.path] || {
        page: 1,
        size: 10,
      }
      this.getOptions();
      this.getList();
      this.remoteMethod();
  },
  watch: {
    $route(to,form) {
        window[form.path]=this.queryParams
    },
  },
  methods: {
      remoteMethod(keyword){
          this.$httpGet("/backend/Warehouse/index", {size:1000000,title:keyword}).then((res) => {
              if (res.status == 200) {
                  this.hwlist = res.data.data;
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
      /** 查询列表 */
      getList() {
          this.loading = true;
          let _data={...this.queryParams};
          this.$httpGet("/backend/Warehouse/warehouse_batch_number",_data ).then((res) => {
              if (res.status == 200) {
                  this.loading = false;
                  this.list = res.data.data;
                  this.total = res.data.total;
              } else {
                  this.$message.error(res.message);
              }
              this.loading = false;
          }).catch((err) => {
              console.log(err);
              this.loading = false;
          });
      },
      getOptions(){
          
          this.$httpGet("/backend/Warehouse/map_list", {}).then((res) => {
              if (res.status == 200) {
                  let arr=res.data;
                  let list=[];
                  for(let i=0;i<arr.length;i++){
                      list.push({id:i.toString(),title:arr[i]})
                  }
                  this.sllist = list;
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
      /** 搜索按钮操作 */
      handleQuery() {
          this.queryParams.page = 1;
          this.getList();
      },
      resetQuery(){
          this.queryParams={
              page:1,
              size:10,
              time:''
          }
          this.getList();
      }
  },
};
</script>

<style lang="scss" scoped>
.search_box {
  background: #f2f3f5;
  border-radius: 2px;
  padding: 15px;
}
.mr_20 {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
}
</style>

